@use "sass:map";
@import "@Styles/variables.scss";

.block {
   padding: 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }

   .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      font-style: normal;
      display: flex;
      align-items: center;
      @include media-down(md) {
         font-size: 18px;
         line-height: 24px;
      }

      svg {
         width: 32px;
         height: 32px;
         margin-right: 8px;
         @include media-down(md) {
            width: 24px;
            height: 24px;
            margin-right: 4px;
         }
      }
   }

   .sub-title {
      color: #697f90;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      @include media-down(md) {
         font-size: 14px;
         font-weight: 400;
         line-height: 20px;
         margin-bottom: 16px;
         margin-top: 4px;
      }
   }

   .block-sub {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      @include media-down(md) {
         margin-bottom: 0;
      }
   }

   .view-all {
      font-size: 18px;
      color: $primary;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      align-items: center;

      .viewall-text {
         @include media-down(md) {
            display: none;
         }
      }

      .next-icon {
         width: 32px;
         height: 32px;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-left: 8px;
         border-radius: 8px;
         background: #ffefed;
         @include media-down(md) {
            width: 24px;
            height: 24px;
         }
      }
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}
