@use "sass:map";
@import "styles/variables.scss";

.spacet-partner {
   margin-top: 12px;
   .partner-item {
      padding: 20px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
      background: #fff;
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}

.block {
   padding: 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }
}

.title {
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
   display: flex;
   align-items: center;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }

   svg {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      @include media-down(md) {
         width: 24px;
         height: 24px;
         margin-right: 4px;
      }
   }
}
