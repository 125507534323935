@use "sass:map";
@import "styles/variables.scss";

.slider-item {
   border-radius: 8px;
   box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
   overflow: hidden;
   .img {
      position: relative;
      order: 2;
      .row {
         height: 100%;
      }
      img.user-img {
         width: 100%;
         aspect-ratio: 1.6;
         object-fit: cover;
      }
   }
   .wrap-avatar img {
      width: 48px;
      height: 48px;
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      border-radius: 100px;
      border: 3px solid #fff;
      overflow: hidden;
      object-fit: cover;
      object-position: center center;

      position: absolute;
      top: 12px;
      left: 12px;
      @include media-down(md) {
         width: 40px;
         height: 40px;
         min-width: 40px;
         min-height: 40px;
         max-width: 40px;
         max-height: 40px;

         position: absolute;
         top: 8px;
         left: 8px;
      }
   }
}

.review-detail {
   background: #fbfbfc;
   padding: 12px;
   order: 1;
   @include media-down(md) {
      padding: 8px;
   }
   &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      @include media-down(md) {
         font-size: 16px;
      }
   }

   &__sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
      @include media-down(md) {
         font-size: 14px;
         margin-top: 4px;
      }
   }

   &__rating {
      width: 100%;
      margin-top: 8px;
      display: flex;
      align-items: center;
      @include media-down(md) {
         margin-top: 4px;
      }

      img {
         height: 32px;
         @include media-down(md) {
            height: 24px;
         }
      }

      span {
         font-weight: 400;
         font-size: 20px;
         line-height: 28px;
         margin-left: 8px;
         @include media-down(md) {
            font-size: 16px;
            margin-left: 4px;
         }
      }
   }

   &__des {
      color: #252a2b;
      text-align: justify;
      padding: 12px;
      border-radius: 8px;
      background: #f5f5fa;
      margin-top: 16px;
      min-height: 146px;
      @include media-down(md) {
         font-size: 14px;
         padding: 8px;
         margin-top: 8px;
         min-height: 100px;
      }
      img {
         width: 10px;
         display: inline-flex;
         top: 0;
      }
   }

   &__date {
      color: #697f90;
      text-align: end;
      padding: 0;
      margin: 0;
      margin-top: 16px;
      @include media-down(md) {
         font-size: 14px;
         margin-top: 8px;
      }
   }
}

.main_title {
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
   font-style: normal;
   display: flex;
   align-items: center;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}

.block {
   padding: 24px 24px 12px 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }
}
