@use "sass:map";
@import "styles/variables.scss";

.main-category {
   background: #fff;
   padding: 12px 0 12px;

   @include media-down(md) {
      padding: 0px;
   }
}

.st-container {
   width: 100%;
   max-width: 1232px;
   margin: 0 auto;
   padding: 24px;
   @include media-down(md) {
      padding: 0px;
   }
}

.menu {
   padding-top: 4px !important;

   &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #252a2b;

      .image {
         display: flex;
         align-items: center;
         justify-content: center;
         background: #f2f5fa;
         border-radius: 12px;
         width: 88px;
         height: 88px;
         padding: 2px;

         img,
         picture {
            width: 100%;
         }
      }

      span {
         margin-top: 8px;
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
         text-align: center;
         max-width: 94px;
      }
   }
}

.title {
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   text-align: center;
   color: #252a2b;
   margin-top: 12px;
   white-space: pre-wrap;
}
