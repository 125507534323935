@use "sass:map";
@import "styles/variables.scss";

.hornor-brand {
   background: #fff;
   padding: 0px 0 0px;

   @include media-down(md) {
      padding: 16px 0 16px;
      margin-top: 16px;
   }

   .section-title {
      font-size: 24px;
      font-style: normal;
      line-height: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 16px;

      @include media-down(md) {
         font-size: 18px;
         line-height: 24px;
         margin-bottom: 12px;
         text-align: center;
      }
   }
}

.container {
   @include media-down(md) {
      padding-left: 10px;
      padding-right: 10px;
   }
}
