@use "sass:map";
@import "styles/variables.scss";

.title {
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
   display: flex;
   align-items: center;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }

   svg {
      width: 32px;
      height: 32px;
      margin-right: 8px;
      @include media-down(md) {
         width: 24px;
         height: 24px;
         margin-right: 4px;
      }
   }
}
