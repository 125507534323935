@use "sass:map";
@import "@Styles/variables.scss";

.construction-design {
   .filter-cate {
      display: flex;
      flex-flow: row wrap;
      margin-top: 16px;
      @include media-down(md) {
         flex-flow: row nowrap;
         overflow: auto hidden;
         border-bottom: 1px solid $border;
         &::-webkit-scrollbar {
            display: none;
         }
      }
      .filter-item {
         line-height: 26px;
         padding: 2px 12px;
         min-width: 50px;
         height: 30px;
         border-radius: 100px;
         background: #f5f5f5;
         margin-right: 8px;
         margin-bottom: 12px;
         text-align: center;
         white-space: nowrap;
         cursor: pointer;
         @include media-up(md) {
            &:hover {
               color: #fff;
               background-color: $primary;
            }
         }
         @include media-down(md) {
            font-size: 14px;
            background: unset;
            border-radius: 0;
            min-width: unset;
            margin: 0;
            margin-right: 16px;
            height: unset;
            padding: 6px 0 6px 0;
         }

         &.active {
            color: #fff;
            font-weight: 500;
            background-color: $primary;
            @include media-down(md) {
               color: $primary;
               background-color: #fff;
               border-bottom: 1px solid $primary;
            }
         }
      }
   }
   .slick-slider {
      margin: 0 -10px;
      @include media-down(md) {
         margin: 0 -4px;
      }
   }
   .slider-item {
      padding: 10px 6px 0px 6px;
      @include media-down(md) {
         padding: 8px 4px 0px;
      }
      .img {
         aspect-ratio: 1.4;
         border-radius: 8px;
         overflow: hidden;
         img {
            width: 100%;
            object-fit: cover;
         }
      }
      .article-item-info {
         overflow: hidden;
         margin-top: 8px;
      }
      .article-item-info-title {
         color: $text;
         font-size: 16px;
         line-height: 22px;
         margin-top: 8px;
         @include media-down(md) {
            font-size: 14px;
            line-height: 20px;
            margin-top: 4px;
         }
      }
   }
}

.truncate-line-2 {
   display: -webkit-box;
   -webkit-line-clamp: 2; /* Limit to 2 lines */
   -webkit-box-orient: vertical; /* Ensure vertical text alignment */
   overflow: hidden;
   text-overflow: ellipsis;
   line-height: 22px; /* Adjust line spacing if needed */
   max-height: 44px; /* line-height × 2 */
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   text-align: left;
   color: #252a2b;
}
