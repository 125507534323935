@use "sass:map";
@import "styles/variables.scss";

.content {
   border-radius: 8px;
   background: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px;

   @include media-up(md) {
      border: 1px solid #f0f0f0;
   }

   @include media-down(md) {
      padding: 0px;
   }
}

.main_title {
   font-size: 24px;
   font-weight: 600;
   font-style: normal;
   line-height: 32px;
   display: flex;
   align-items: center;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }
}

.title {
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   text-align: center;
}

.item {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 16px;
}

.block {
   padding: 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}

.spacet-partner {
   margin-top: 12px;
   @include media-down(md) {
      // margin-left: -12px;
   }
   // .partner-item {
   //    padding: 20px;
   //    border-radius: 8px;
   //    border: 1px solid #f0f0f0;
   //    background: #fff;
   // }
}
