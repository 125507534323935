@use "sass:map";
@import "styles/variables.scss";

.slider-item {
   margin-left: 0px;
   margin-right: 0px;
}

.main_title {
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
   display: flex;
   align-items: center;
   font-style: normal;
   @include media-down(md) {
      font-size: 18px;
      line-height: 24px;
   }
}

.content {
   border-radius: 8px;
   border: 1px solid #f0f0f0;
   background: #fff;
   @include media-down(md) {
      margin-right: 8px;
   }
}

.title1 {
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   text-align: left;
   color: #252a2b;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 10px;
}

.title2 {
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   text-align: left;
   color: #697f90;
   padding-top: 8px;
   padding-bottom: 8px;
   padding-left: 10px;
}

.item {
   align-items: center;
   gap: 16px;
}

.image-container {
   display: flex;
   justify-content: space-between;
}

.button_wrapper {
   margin-left: 10px;
   margin-right: 10px;
   margin-bottom: 10px;
}

.block {
   padding: 24px;
   background: #ffffff;
   border-radius: 8px;
   @include media-down(md) {
      padding: 16px 12px;
      border-radius: 0;
   }
}

.block-sub {
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
   @include media-down(md) {
      margin-bottom: 0;
   }
}

.spacet-partner {
   margin-top: 12px;
   @include media-down(md) {
      // margin-left: -10px;
   }
   // .partner-item {
   //    padding: 20px;
   //    border-radius: 8px;
   //    border: 1px solid #f0f0f0;
   //    background: #fff;
   // }
}
